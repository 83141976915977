import React from 'react';
import './App.css'
import { Grommet, Box } from 'grommet';
import { useSpring, animated } from 'react-spring'


const theme = {
  global: {
    colors: {
      brand: '#323133',
      'neutral-1': '#202020'
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const trans2 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`

/*const AppBar = (pops) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'large' }}
    elevation='medium'
    style={{ zIndex: '1' }}
  />)*/

function App() {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <Grommet theme={theme}>
      <Box fill="horizontal" margin={{top:"large"}}>
        <Box responsive align="center" className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
            <animated.div className="planet" style={{ transform: props.xy.interpolate(trans1) }} />
            <animated.div className="korppi" style={{ transform: props.xy.interpolate(trans2) }} />
        </Box>  
      </Box>
    </Grommet>
  );
}

export default App;
